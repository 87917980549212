import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalIndexTemplateService {
  private _close: Subject<any>;
  private _update: boolean;
  constructor() {
    this._Close = new Subject<boolean>()
    this.Update = false;
  }

  private get _Close(): Subject<boolean> {
    return this._close;
  }

  private set _Close(close: Subject<boolean>) {
    this._close = close;
  }

  get Update(): boolean {
    return this._update;
  }

  set Update(update: boolean) {
    this._update = update;
  }

  get Close(): Subject<boolean> {
    return this._close;
  }

  public EventClose(): void {
    this._Close.next(this.Update);
  }
}
