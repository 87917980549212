import { Injectable } from '@angular/core';
import { Principal } from '@app/principal/principal.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrincipalService {

  constructor() { }

  private _HojaRuta: Principal.HojasRutaDto;
  HojaRutaSubject = new Subject<Principal.HojasRutaDto>();

  set HojaRuta(hojaRuta) {
    this._HojaRuta = hojaRuta;
    this.HojaRutaSubject.next(hojaRuta);
  }

  get HojaRuta() {
    return this._HojaRuta;
  }

  private _Gestion: Principal.Gestion;
  GestionSubject = new Subject<Principal.Gestion>();

  set Gestion(gestion) {
    this._Gestion = gestion;
    this.GestionSubject.next(gestion);
  }

  get Gestion() {
    return this._Gestion;
  }
  
}
