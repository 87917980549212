import { Component, OnInit, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalIndexTemplateService } from './principal/modal-index-template.service';
import { AUTH_SERVICE, IAuthService, USER_AUTH, IUserAuth } from 'vesta-authentication'
import { WebPushFirebaseService } from 'vesta-firebase';
import { DialogService, CallHttp } from 'vesta';
import { NavBarService, MensajeNavBar } from 'nav-bar-mensajes';
import { VestaMenuService } from 'vesta-menu';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentoRequestVm, FileshareMultipleComponent } from 'fileshare';
import { Local } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(private route: Router,
    //private activatedRoute: ActivatedRoute,
    private modalIndexTemplateService: ModalIndexTemplateService,
    @Inject(AUTH_SERVICE) private authService: IAuthService,
    @Inject(USER_AUTH) private userAuth: IUserAuth,
    private webPush: WebPushFirebaseService,
    private navBarService: NavBarService,
    private dialogService: DialogService,
    private menuService: VestaMenuService,
    private callHttp: CallHttp,
    private sanitizer: DomSanitizer
  ) { }

  width: string;
  opDark: string;
  src: string;
  user: IUserAuth = this.userAuth;
  _navBarService = this.navBarService;
 
  async ngOnInit() {    
    //this.user = await this.GetUser();
    
    //this.testFileShare()
    this.width = '90%';
    this.opDark = 'op-dark';

    $('#off-canvas').offcanvas({
      supportNoTransitionsClass: 'support-no-transitions'
    }).on('close.offcanvas', (e: any) => {
      let elementos = $('.searchModal').css('visibility');
      if (elementos != 'visible') {
        $(`body`).css('overflow-y', 'auto');
        setTimeout(() => {
          $(window).scrollTop(window['scrollLast']);
        }, 3);
      }
      this.modalIndexTemplateService.EventClose();

      let [url] = this.route.url.split('(');
      let direccion = url;
      this.route.navigateByUrl(direccion);
    });

    $('#off-canvas').on('resizing.offcanvas', function (e) {
      $('#off-canvas').data('offcanvas-component').open();
    });

    this.userAuth.UserEvent.subscribe(async response => {
      if (response.Id == null) return void 0;
      this.user = response;


      let httpsParent = true;
      for (let i = 0; i < location.ancestorOrigins.length; i++) {
        let url = location.ancestorOrigins.item(i);
        let https = url.substring(0, 5);

        if (https != 'https') httpsParent = false;
      }
      console.log(`https ${httpsParent}`);

      if (httpsParent) {
        let resp = await this.NotificacionFirebase();
        if (resp == false) return void 0;

        let mensajes = await this.webPush.GetMensajesUsuario();
        let mensajeNavBar = mensajes.map(m => MensajeNavBar.GetMensajeNavBar(m.NotificacionId, m.body, true, m.click_action));
        this.navBarService.GetMensajesUsuario(mensajeNavBar, this.user.Id);

        let favorites = await this.GetFavoritesMenu();
        this.menuService.Favorites = favorites;
      }
      
    });

    this.EventMenu()
  }

  async  GetFavoritesMenu() {
    let favorites = await this.webPush.Connection().DataBase.ref(`menufavorites/${this.user.Id}`).once('value');
    var favoritesArray = [];

    favorites.forEach(f => {
      favoritesArray.push(f.val());
    })

    return favoritesArray;
  }

  EventMenu() {
    this.menuService.FavoritesEvent.subscribe(response => {
      if (response.Add) this.SaveFavorites(response.Favorite);
      else if (!response.Add) this.DeletedFavorites(response.Favorite);
    })
  }

  SaveFavorites(favorite: any) {
    let data = { UserId: this.user.Id, Token: this.webPush.TokenId, FavoritesOrder: favorite }
    this.callHttp.httpPost('api/FireBaseApi/SaveMenuFavorites', data).subscribe(response => {
      console.log(response);
    }, error => {
      console.log(error);
    })
  }

  DeletedFavorites(favorite: any) {
    this.webPush.Connection().DataBase.ref(`menufavorites/${this.user.Id}/${favorite.Id}`).remove()
      .catch(error => {
        console.log(error);
      })
  }

  //url = this.sanitizer.bypassSecurityTrustResourceUrl('');
  //TestUrl() {
  //  let idGestion = 'cb9725b-5184-4a13-9b94-1f6979840350';
  //  let numeroGestion = encodeURIComponent(btoa('PR-CE-22-7029'));
  //  let clienteJson = { "ClienteId": "91ef67a2-4f50-44ee-b0e5-0f27e8dfb778", "ClienteDescripcion": "CERVECERIA HONDUREA SA DE CV", "GestionProveedorId": "bc50b3f7-01bd-4e12-9c17-14db41c01db7", "GestionProvedorDescripcion": "AMCOR RIGID PACKAGING CENTROAMERICA, S.A DE C.V", "GestionTipoCargaId": "b2f8bd2f-df8f-4ca8-9d07-153b8fd8c387", "GestionTipoCargaDescripcion": "GENERAL - COMPLETO - SECO" }
  //  let json = JSON.stringify(clienteJson);
  //  let cliente = encodeURIComponent(btoa(json));

  //  let userId = '955e988e-7420-4136-8d2e-133423455c09';
  //  let sitioId = 'a36a911f-e6f9-4e07-8602-686e02420e0f';

    
  //  //let gestionesImport = encodeURIComponent(btoa(JSON.stringify([{ "GestionId": "8da3b8b8-c0af-4219-997e-1d80c45104eb", "NumeroGestion": "PR-CE-22-170" }, { "GestionId": "0bf97e14-8117-434f-8da1-1d82e66c2098", "NumeroGestion": "PR-CE-22-196" }])))
   
  //  let url = `http://localhost:54895/operaciones/digitarfacturachsa/home/Index/${idGestion}/${numeroGestion}/${cliente}/${userId}/${sitioId}`;
  //  //this.route.navigateByUrl(`/operaciones/digitarfacturachsa/home/Index/${idGestion}/${numeroGestion}/${cliente}/${userId}/${sitioId}`)
  //  this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  //}

  NotificacionFirebase() {
    let promise = new Promise<boolean>(async (resolve, reject) => {
      let resp = await this.webPush.RequestPermission(this.user);

      this.webPush.OnMessaging.subscribe(payload => {
        this.dialogService.NotificacionWarningTitle(payload.notification.body, payload.notification.title);
        var mensaje = MensajeNavBar.GetMensajeNavBar(payload.notification.NotificacionId, payload.notification.body, true, payload.notification.click_action);
        this.navBarService.AddMensajes(mensaje);
      });

      this.webPush.FocusSubject.subscribe((mensajes) => {
        //let mensajesTemp = [];
        mensajes.forEach(f => {
          var mensaje = MensajeNavBar.GetMensajeNavBar(f.notification.NotificacionId, f.notification.body, true, f.notification.click_action);
          this.navBarService.AddMensajes(mensaje);
        })
      })

      this.navBarService.Deleted.subscribe(id => {
        this.webPush.DeleteNotificacion(id);
      })

      resolve(resp);
    })

    return promise;
  }


  async GetUser() {
    this.user = await this.userAuth.User;
    if (this.user.Id == null) return void 0;
    return this.user;
    //this.webPush.RequestPermission();
  }

  ngAfterViewInit(): void {
    $(`#idDialogRouter`).attr('data-role', 'dialog');
    $(`#idDialogRouter`).attr('data-place', 'top-center');
  }

  closeModal(): void {
    var dataOffcanvas = $('#off-canvas').data('offcanvas-component');
    dataOffcanvas.close();
  }

  Logout() {
    this.webPush.Connection().Auth.signOut()
    this.authService.signoutRedirect();
  }

    NavOpen(): void {
    this.navBarService.OpenCloseNav();
  }
}
