import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgProgressModule } from "@ngx-progressbar/core";
import { NgProgressRouterModule } from "@ngx-progressbar/router";
import { AccesoDenegadoComponent } from 'vesta';//PageNotFoundComponent
import { ProxyRouteComponent } from './proxy-route/proxy-route.component';
import { VestaMenuComponent } from 'vesta-menu';
import { AuthorizationGuard } from 'vesta-authentication';

const routes: Routes = [
  {
    path: "", redirectTo: "menu", pathMatch: "full"
  },
  {
    path: "login/accessdenied", component: AccesoDenegadoComponent
  },
  {
    path: "menu",
    component: VestaMenuComponent, canActivate: [AuthorizationGuard]
  },
  {
    path: "Mantenimientos", children: [

    ]
  },
  {
    path: "operaciones", children: [
      {
        path: "solicitudpagoinformacioncomplementaria",
        loadChildren: "./solicitud-pago-informacion-complementaria/solicitud-pago-informacion-complementaria.module#SolicitudPagoInformacionComplementariaModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "checklistsugerido",
        canActivate: [AuthorizationGuard],
        loadChildren: "./check-list/check-list.module#CheckListModule",
      },
      {
        path: 'reasignaciongestores',
        loadChildren: './reasignacion-gestores/reasignacion-gestores.module#ReasignacionGestoresModule',
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'reasignaciongestoresdocumentos',
        loadChildren: './reasignacion-gestores-documentos/reasignacion-gestores-documentos.module#ReasignacionGestoresDocumentosModule',
        canActivate: [AuthorizationGuard]
      },
      {
        path: "solicitudesmultiples",
        canActivate: [AuthorizationGuard],
        loadChildren: "./solicitudes-multiples/solicitudes-multiples.module#SolicitudesMultiplesModule"
      },
      {
        path: "digitalizaciongestion",
        canActivate: [AuthorizationGuard],
        loadChildren: "./digitalizacion-gestion/digitalizacion-gestion.module#DigitalizacionGestionModule",
      },
      {
        path: "digitalizacion",
        loadChildren: "./digitalizacion/digitalizacion.module#DigitalizacionModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "carga",
        loadChildren: "./carga/carga.module#CargaModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "crearinstruccion",
        loadChildren: "./crear-instruccion/crear-instruccion.module#CrearInstruccionModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "digitarfactura",
        loadChildren: "./digitar-factura/digitar-factura.module#DigitarFacturaModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "documentostemporalesrelacion",
        loadChildren: "./documentos-temporales-relacion/documentos-temporales-relacion.module#DocumentosTemporalesRelacionModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "solicitudclasificacioninbound",
        loadChildren: "./solicitud-clasificacion-inbound/solicitud-clasificacion-inbound.module#SolicitudClasificacionInboundModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'solicitudclasificacionproveedorsku',
        loadChildren: './solicitud-clasificacion-proveedor-sku/solicitud-clasificacion-proveedor-sku.module#SolicitudClasificacionProveedorSkuModule',
        canActivate: [AuthorizationGuard]
      },
      {
        path: "documentoscertificadosuad",
        canActivate: [AuthorizationGuard],
        loadChildren: "./documentos-certificados-uad/documentos-certificados-uad.module#DocumentosCertificadosUadModule"
      },
      {
        path: "digitarfacturachsa",
        loadChildren: "./digitar-factura-chsa/digitar-factura.module#DigitarFacturaModule"
      },
      {
        path: "digitarfacturachsaexport",
        loadChildren: "./digitar-factura-chsa-export/digitar-factura.module#DigitarFacturaModule"
      },
      {
        path: "digitardocumentosvarios",
        canActivate: [AuthorizationGuard],
        loadChildren: "./digitar-documentos-varios/digitar-documentos-varios.module#DigitarDocumentosVariosModule"
      },
      {
        path: "digitalizacionexterno",        
        loadChildren: "./digitalizacion-externo/digitalizacion.module#DigitalizacionModule"
      },
      {
        path: "digitardocumentosvariosaforo",
        //canActivate: [AuthorizationGuard],
        loadChildren: "./digitar-documentos-varios-aforo/digitar-documentos-varios-aforo.module#DigitarDocumentosVariosAforoModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "informacioncomplementariamateriaprima",
        canActivate: [AuthorizationGuard],
        loadChildren: "./informacion-complementaria-materia-prima/informacion-complementaria-materia-prima.module#InformacionComplementariaMateriaPrimaModule"
      },
      {
        path: "declaracionsugerenciarechazo",
        canActivate: [AuthorizationGuard],
        loadChildren: "./declaracion-sugerencia-rechazo/declaracion-sugerencia-rechazo.module#DeclaracionSugerenciaRechazoModule"
      },
      {
        path: "informacioncomplementariadeclaracioningreso",
        canActivate: [AuthorizationGuard],
        loadChildren: "./informacion-complementaria-declaracion-ingreso/informacion-complementaria-declaracion-ingreso.module#InformacionComplementariaDeclaracionIngresoModule"
      },
      {
        path: "materiaprimaexcelsarah",
        canActivate: [AuthorizationGuard],
        loadChildren: "./materia-prima-excel-sarah/materia-prima-excel-sarah.module#MateriaPrimaExcelSarahModule"
      },
      {
        path: "actualizarinfocorreo",
        canActivate: [AuthorizationGuard],
        loadChildren: "./actualizar-info-correo/actualizar-info-correo.module#ActualizarInfoCorreoModule"
      },
      {
        path: "gestioncliente",
        loadChildren: "./gestion-cliente/gestion-cliente.module#GestionClienteModule"
      },
      {
        path: "resolucionrit",
        canActivate: [AuthorizationGuard],
        loadChildren: "./resolucion-rit/resolucion-rit.module#ResolucionRitModule"
      },
      {
        path: "registrodeeventos",
        loadChildren: "./registro-de-eventos/registro-de-eventos.module#RegistroDeEventosModule"
      },
      {
        path: "coladetrabajosanbartolo",
        loadChildren: "./cola-de-trabajo-san-bartolo/cola-de-trabajo-san-bartolo.module#ColaDeTrabajoSanBartoloModule"
      },
      {
        path: "pregestiones",
        loadChildren: "./principal-cliente-externo/principal-cliente.module#PrincipalClienteModule",
      },
    ]
  },
  {
    path: "operaciones", component: ProxyRouteComponent, outlet: "modal",
    children: [
      {
        path: "checklistsugerido",
        canActivate: [AuthorizationGuard],
        loadChildren: "./check-list/check-list.module#CheckListModule"
      },
      {
        path: "solicitudesmultiples",
        canActivate: [AuthorizationGuard],
        loadChildren: "./solicitudes-multiples/solicitudes-multiples.module#SolicitudesMultiplesModule"
      },
      {
        path: "digitalizaciongestion",
        canActivate: [AuthorizationGuard],
        loadChildren: "./digitalizacion-gestion/digitalizacion-gestion.module#DigitalizacionGestionModule",
      },
      {
        path: "digitalizacion",
        loadChildren: "./digitalizacion/digitalizacion.module#DigitalizacionModule"
      },
      {
        path: "carga",
        loadChildren: "./carga/carga.module#CargaModule"
      },
      {
        path: "crearinstruccion",
        loadChildren: "./crear-instruccion/crear-instruccion.module#CrearInstruccionModule"
      },
      {
        path: "digitarfactura",
        loadChildren: "./digitar-factura/digitar-factura.module#DigitarFacturaModule"
      },
      {
        path: "digitarfacturachsa",
        loadChildren: "./digitar-factura-chsa/digitar-factura.module#DigitarFacturaModule"
      },
      {
        path: "documentostemporalesrelacion",
        loadChildren: "./documentos-temporales-relacion/documentos-temporales-relacion.module#DocumentosTemporalesRelacionModule"
      },
      {
        path: "documentoscertificadosuad",
        loadChildren: "./documentos-certificados-uad/documentos-certificados-uad.module#DocumentosCertificadosUadModule"
      },
      {
        path: "digitalizacioncliente",
        loadChildren: "./digitalizacion-cliente/digitalizacion-cliente.module#DigitalizacionClienteModule"
      },
      {
        path: "gestioncliente",
        loadChildren: "./gestion-cliente/gestion-cliente.module#GestionClienteModule"
      },     
      
    ]
  },
  {
    path: "workflows", children: [
      {
        path: "gestorlogistico",
        loadChildren: "./principal/principal.module#PrincipalModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "colatrabajouad",
        loadChildren: "./cola-trabajo-analista-documentos/cola-trabajo-analista-documentos.module#ColaTrabajoAnalistaDocumentosModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "analistaaforo",
        loadChildren: "./analista-aforo/analista-aforo.module#AnalistaAforoModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "gestordelicencias",
        loadChildren: "./gestor-licencias-solicitudes/gestor-licencias-solicitudes.module#GestorLicenciasSolicitudesModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "gestordelicenciasexterno",
        loadChildren: "./gestor-licencias-solicitudes-externo/gestor-licencias-solicitudes-externo.module#GestorLicenciasSolicitudesExternoModule",
        //canActivate: [AuthorizationGuard]
      },
      {
        path: "revisionmanifiesto",
        loadChildren: "./revision-manifiesto/revision-manifiesto.module#RevisionManifiestoModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "notificacionuad",
        loadChildren: "./notificacion-unidad-analisis-documentos/notificacion-unidad-analisis-documentos.module#NotificacionUnidadAnalisisDocumentosModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "solicituddocumentoprovisional",
        loadChildren: "./solicitud-documento-provisional/solicitud-documento-provisional.module#SolicitudDocumentoProvisionalModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "remisionpermisos",
        loadChildren: "./remision-permisos/remision-permisos.module#RemisionPermisosModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "revisiondeclaracionsugerida",
        loadChildren: "./revision-aprobacion-cliente-materia-prima/revision-aprobacion-cliente-materia-prima.module#RevisionAprobacionClienteMateriaPrimaModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "aprobacionclientemateriaprima",
        loadChildren: "./aprobacion-cliente-materia-prima/aprobacion-cliente-materia-prima.module#AprobacionClienteMateriaPrimaModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "materiaprimaaforo",
        canActivate: [AuthorizationGuard],
        loadChildren: "./materia-prima-aforo/materia-prima-aforo.module#MateriaPrimaAforoModule"
      },
      {
        path: "preliquidacioncliente",
        canActivate: [AuthorizationGuard],
        loadChildren: "./preliquidacion-cliente/preliquidacion-cliente.module#PreliquidacionClienteModule"
      },
      {
        path: "preliquidacionclienteexterno",
        //canActivate: [AuthorizationGuard],
        loadChildren: "./preliquidacion-cliente-externo/preliquidacion-cliente.module#PreliquidacionClienteModule"
      },
      {
        path: "principalclienteexterno",
        loadChildren: "./principal-cliente-externo/principal-cliente.module#PrincipalClienteModule",
      },
      {
        path: "pendienteclasificacion",
        canActivate: [AuthorizationGuard],
        loadChildren: "./pendiente-clasificacion/pendiente-clasificacion.module#PendienteClasificacionModule",
      },
      {
        path: "remisiondocumentos",
        loadChildren: "./remision-documentos/remision-documentos.module#RemisionDocumentosModule"
      },
      {
        path: "aduanasinpapeles",
        loadChildren: "./aduana-sin-papeles/aduana-sin-papeles.module#AduanaSinPapelesModule"
      },
      {
        path: "documentoscorrectosincorrectos",
        loadChildren: "./documentos-correctos-incorrectos/documentos-correctos-incorrectos.module#DocumentosCorrectosIncorrectosModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "documentoscorrectos",
        loadChildren: "./documentos-correctos/documentos-correctos.module#DocumentosCorrectosModule"
      },
      {
        path: "tlcglobal",
        loadChildren: "./tlc-global/tlc-global.module#TlcGlobalModule"
      },
      {
        path: "pregestiones",
        loadChildren: "./pre-gestion-interna/pre-gestion-interna.module#PreGestionInternaModule"
      },
      
    ]
  },
  {
    path: "reportes", children: [
      {
        path: "clasificacion",
        loadChildren: "./reporte-clasificacion/reporte-clasificacion.module#ReporteClasificacionModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "manifiesto",
        loadChildren: "./reporte-manifiesto/reporte-manifiesto.module#ReporteManifiestoModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "fyduca",
        loadChildren: "./reporte-fyduca/reporte-fyduca.module#ReporteFyducaModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reportedeclaraciones",
        loadChildren: "./reporte-rit/reporte-rit.module#ReporteRitModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reporteduca",
        loadChildren: "./reporte-declaracion-aduanera-salida-duca/reporte-declaracion-aduanera-salida-duca.module#ReporteDeclaracionAduaneraSalidaDucaModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reportedesarrolloeconomico",
        loadChildren: "./reporte-desarrollo-economico/reporte-desarrollo-economico.module#ReporteDesarrolloEconomicoModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reportedesarrolloeconomicoexport",
        loadChildren: "./reporte-desarrollo-economico-export/reporte-desarrollo-economico-export.module#ReporteDesarrolloEconomicoExportModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reportepreimpresofactura",
        loadChildren: "./reporte-pre-impreso-factura/reporte-pre-impreso-factura.module#ReportePreImpresoFacturaModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reportepagaresglobales",
        loadChildren: "./reporte-pagares-globales/reporte-pagares-globales.module#ReportePagaresGlobalesModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "declaracioningresografico",
        loadChildren: "./reporte-declaracion-ingreso-grafico/reporte-declaracion-ingreso-grafico.module#ReporteDeclaracionIngresoGraficoModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "declaracionsalidagrafico",
        loadChildren: "./reporte-declaracion-salida-grafico/reporte-declaracion-salida-grafico.module#ReporteDeclaracionSalidaGraficoModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "valorresidual",
        loadChildren: "./reporte-valor-residual/reporte-valor-residual.module#ReporteValorResidualModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reporetesolicitudpermisos",
        loadChildren: "./reporte-solicitud-permisos/reporte-solicitud-permisos.module#ReporteSolicitudPermisosModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "declaracionescliente",
        loadChildren: "./reporte-declaraciones-cliente/reporte-declaraciones-cliente.module#ReporteDeclaracionesClienteModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reportegarantiaschsa",
        loadChildren: "./reporte-garantias-chsa/reporte-garantias-chsa.module#ReporteGarantiasChsaModule"
      },
    ]
  },
  {
    path: "solicitudesflete", children: [
      {
        path: "solicitudesultimamilla",
        loadChildren: "./solicitudes-ultima-milla/solicitudes-ultima-milla.module#SolicitudesUltimaMillaModule"
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    RouterModule, NgProgressModule.withConfig({
      trickleSpeed: 200,
      min: 20,
      meteor: false,
      color: 'red'
    }),
    NgProgressRouterModule
  ],
  exports: [NgProgressModule, RouterModule]
})
export class AppRoutingModule { }
