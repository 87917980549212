import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'proxy-component-iframe',
  templateUrl: './proxy-component-iframe.component.html',
  styleUrls: ['./proxy-component-iframe.component.css']
})
export class ProxyComponentIframeComponent implements OnInit {

  constructor() { }

  url: string;
  ngOnInit() {
  }

}
