import { Injectable } from '@angular/core';
import { CallHttp } from 'vesta';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UsuarioInfoPersonaExternoService {

  constructor(private callHttp: CallHttp) { }

  private _personaId: string;
  private _infoPersona: Persona.InfoPersona;
  private _user: { Id: number, User: string, PersonaId: string };

  get infoPersona() {
    if (this._infoPersona)
    return this._infoPersona;
  }

  get User() {
    return this._user;
  }

  set personaId(personaId: string) {
    if (this._personaId != personaId) (async () => {
      this._personaId = personaId
      await this.getInfoPersona()
    })();

    this._personaId = personaId;
  }

  portalClienteUser(user: { Id: number, User: string, PersonaId: string }) {
    this._user = user;
  }

  private async getInfoPersona() {
    let persona = await this.callHttp.httpPost<Persona.InfoPersona>(`${environment.webServices.Personas}/PersonasServiceApi/GetPersonaByIdV3`,
      { PersonaId: this._personaId }).toPromise()

    this._infoPersona = persona;
  }
}


export namespace Persona {
  export interface InfoPersona {
    Id: string;
    Nombre: string;
    Apellido: string;
    IdFiscal: string;
    CodigoErp: string;
    UsuarioErp: string;
    CodigoEmpleado: string;
    TipoIdFiscalDescripcion: string;
    GrupoPersonaNombre: string;
    GrupoPersonaId: string;
    Grupo: Grupo;
    NombreUsuario: string;
    Url: string;
    RazonSocial: string;
    PaisId: string;
    PaisDescripcion: string;
    LimiteDeCredito: number;
    TiempoDeCredito: number;
    SegmentoId: string;
    SegmentoNombre: string;
    TipoDeServicioNombre: string;
    Caracter: string;
    GranContribuyente: boolean;
    CatalogoRelacion: CatalogoRelacion[];
    CuentasBancoPersona: CuentasBancoPersona[];
    Sitios: Sitio[];
    CodigosErp: CodigosERP[];
  }

  export interface CatalogoRelacion {
    Id: string;
    Unique: number;
    TipoRelacionDescripcion: string;
    TipoRelacion: TipoRelacion;
    TipoRelacionId: string;
    Relaciones: Relacione[];
  }

  export interface Relacione {
    Id: string;
    RolRelacion: RolRelacion;
  }

  export interface RolRelacion {
    Id: string;
    Descripcion: string;
  }

  export interface TipoRelacion {
    Id: string;
    Descripcion: string;
    GrupoRelacion: GrupoRelacion;
  }

  export interface GrupoRelacion {
    Id: string;
    Descripcion: string;
    TipoPersonaDestino: number;
  }

  export interface CodigosERP {
    Id: string;
    Codigo: string;
    Sociedad: string;
    Pais: string;
    SistemaId: string;
    SistemaDescripcion: string;
    CondicionPago: string;
    TipoPersonaDestino: string;
  }

  export interface CuentasBancoPersona {
    Id: string;
    Numero: string;
    BancoId: string;
    BancoCodigoBanco: string;
    TipoCuentaId: string;
    TipoCuentaCuentaTipo: string;
    Moneda: string;
    BancoNombre: string;
    TipoCuentaDescripcion: string;
    TipoPersonaDestino: string;
    CuentaContablePago: CuentaContablePago[];
  }

  export interface CuentaContablePago {
    Id: string;
    TipoCuentaContablePago: string;
    Division: string;
    CuentaMayorPago: string;
    PersonaJuridicaFondoId: string;
  }

  export interface Grupo {
    Id: string;
    Nombre: string;
  }

  export interface Sitio {
    Id: string;
    Nombre: string;
    Descripcion: string;
    Direccion: string;
    PaisId: string;
    PaisDescripcion: string;
    CiudadId: string;
    CiudadDescripcion: string;
    CoordenadaX: number;
    CoordenadaY: number;
    TipoDeSitioDescripcion: string;
    Etiquetas: Etiqueta[];
    Almacenes: Almacene[];
    TipoPersonaDestino: string;
  }

  export interface Almacene {
    Id: string;
    Nombre: string;
    Observacion: string;
    Areas?: Almacene[];
  }

  export interface Etiqueta {
    SitioNombre: string;
    EtiquetaId: string;
    EtiquetaDescripcion: string;
  }

}
