import { Component, OnInit, ViewEncapsulation, Input, OnChanges } from '@angular/core';
import { CallHttp } from 'vesta';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'ui-button-proyectos',
  template: `
    <ul class="app-bar-menu small-dropdown float-right">
      <li>
        <a (click)="OpenWindow()" style='display: table; width: 130px;'>
          <div style='display: table-row;text-align: center'>
              <label style='display:table-cell'>Proyectos</label>
              <div  style='display:table-cell'><div class='numberCircle'><strong>{{CantidadAsignado}}</strong></div></div>
          </div>
        </a>
      </li>
    </ul>
  `,//
  styles: [
    `
.numberCircle {
    width: 25px;
    line-height: 25px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    background-color: #FAD428;
    color: black;
}
`
  ],
  encapsulation: ViewEncapsulation.None
})
export class UiButtonProyectosComponent implements OnInit, OnChanges {

  constructor(private http: HttpClient) { }

  @Input("idSistema") idSistema: string;
  @Input("idUsuario") idUsuario: string;
  @Input("nombreUsuario") nombreUsuario: string;
  CantidadAsignado: number = 0;

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    setTimeout(() => {
      this.http.post<{ Catidad: number }>
        (`https://projectvesta.azurewebsites.net/api/ResponsableActividad/CantidadActividadesAsignadas`,
          { IdResponsable: this.idUsuario })
        .subscribe(response => {
          this.CantidadAsignado = (response.Catidad)
        })
    },1000)
    
  }

  OpenWindow() {
    let data = { IdSistema: this.idSistema, IdUsuario: this.idUsuario, NombreUsuario: this.nombreUsuario };
    let base64 = btoa(JSON.stringify(data));
    let url = `http://solicituddeproyectos.azurewebsites.net/todolist/index/${base64}`;
    let tittle = 'Proyectos';
    open(url, tittle || "", 'height=700,width=1250,toolbar=no,menubar=no,location=300,top:100');
  }

}


//{"IdSistema":"20","IdUsuario":"719a23ae-efac-45af-90cd-121ab7a7eae1","NombreUsuario":"DAYSI ARACELY"}
