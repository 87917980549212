import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VestaModule } from 'vesta'
import { ProxyRouteComponent } from './proxy-route/proxy-route.component';
import { VestaAuthenticationModule } from 'vesta-authentication';
import { VestaFirebaseModule } from 'vesta-firebase';
import { NavBarMensajesModule } from "nav-bar-mensajes"
import { FileshareModule } from 'fileshare';
import { environment } from '../environments/environment';
import { VestaMenuModule } from 'vesta-menu';
import { PrincipalService } from '@app/principal/principal.service';
import { UiButtonProyectosComponent } from '@app/ui-button-proyectos.component';
import { ProxyComponentIframeComponent } from './_core/proxy-component-iframe/proxy-component-iframe.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsuarioInfoPersonaExternoService } from './_core/services/usuario-info-persona-externo.service';

@NgModule({
  declarations: [
    AppComponent,
    ProxyRouteComponent,
    UiButtonProyectosComponent,
    ProxyComponentIframeComponent
  ],
  imports: [
    BrowserModule,
    VestaModule.forRoot({ isAuthentication: true }),
    AppRoutingModule,
    VestaAuthenticationModule.forRoot(environment.authentication),
    VestaMenuModule,
    VestaFirebaseModule.forRoot(environment.firebase, { production: environment.production }),
    NavBarMensajesModule,
    FileshareModule.forRoot({ Https: true }),
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: "DireccionBaseHrOld", useValue: environment.webServices.HojaRutaOld },
    { provide: "HojaRuta", useValue: environment.webServices.HojaRuta },
    { provide: "CodigoSistema", useValue: environment.FileShareConfig.CodigoSistema },
    PrincipalService,
    UsuarioInfoPersonaExternoService
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
  ]
})

export class AppModule {
  constructor() {
    console.log(window);
  }
}
