export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBjL-rC_5Uy6kSfCuLhx8qeQXus3z-4IKk",
    databaseURL: "https://notificacionpush-b6f72.firebaseio.com",
    messagingSenderId: "768015207056",
    projectId: "notificacionpush-b6f72",
    appId: "1:768015207056:web:4db85ed948b82eb4",
    PublicVapidKey: "BPIMAR3t8i9BhCR68_5B5vDXyX6fp_315-NLO_XDmcQjDgJMXj9MDSNZ6pV8ZWQmVeicG9Zvp5BD7LPqBM_pYq4",
    SistemaId: '65507ECF-249E-454B-A95A-0061BA0FA2BA'
  },
  authentication: {
    SistemaId: '65507ECF-249E-454B-A95A-0061BA0FA2BA',
    SistemaIdV: 'E455F2A9-5368-464E-A294-0F9DEDA12C85',
    ClienteId: 'HOJADERUTAJS',
    Stage: false
  },
  webServices: {
    HojaRuta: 'https://hojaderutaapi.vesta-accelerate.com/api',
    HojaRutaOld: 'http://aduanaswebapi.grupovesta.com/',
    Personas: 'https://personasapi.vesta-accelerate.com/api',
    AnalisisDeRed: 'https://analisisderedapi.vesta-accelerate.com/api',
    qrscan: 'http://qrscan.grupovesta.com/api',
    Remisiones: 'https://remisionesapi.vesta-accelerate.com/api',
    hojaderutalegacy: 'https://hojaderutalegacy.vesta-accelerate.com/api',
    ultimamilla: 'https://ultimamillaapi.vesta-accelerate.com/api',

  },
  web: {
    Remisiones: 'https://controldedocumentos.vesta-accelerate.com',
    EventoSitios: 'https://eventositios.vesta-accelerate.com/'
  },
  FileShareConfig: { CodigoSistema: '23' }
};
